var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "deletecontact",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "contact",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered",
    attrs: {
      "role": "document"
    }
  }, [_vm.success ? _c('div', {
    staticClass: "modal-content d-flex align-items-center"
  }, [_c('successMessage', {
    attrs: {
      "message": _vm.success
    }
  }), _c('button', {
    staticClass: "__close__success__modal",
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Close")])], 1) : _vm._e(), !_vm.success ? _c('div', {
    staticClass: "modal-content pb-3"
  }, [_c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "padding-right": "1em",
      "padding-left": "1em"
    }
  }, [_vm._m(0), _vm.errors ? _c('Alert', {
    attrs: {
      "message": _vm.errors,
      "type": _vm.type
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "modal__footer__btns"
  }, [_c('div', {
    staticClass: "deleteWrapper"
  }, [_c('el-button', {
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "loading": _vm.loading,
      "type": "danger"
    },
    on: {
      "click": _vm.confirmDisable
    }
  }, [_vm._v(" Disable ")]), _c('el-button', {
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Cancel ")])], 1)])])]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "continue_edit_header pb-2 disable-birthday"
  }, [_c('div', {
    staticClass: "_edit_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/Delete.svg"),
      "height": "33px",
      "width": "31px",
      "alt": "bin icon for delete"
    }
  })]), _c('h3', {
    staticClass: "continue_edit_header_title"
  }, [_vm._v(" Are you sure you want to disable the Birthday App? ")]), _c('p', {
    staticClass: "__contacts_in_group sub-messages"
  }, [_vm._v(" Disabling the birthday app would prevent clients from receiving messages "), _c('br'), _vm._v(" during their birthday. ")])]);
}];
export { render, staticRenderFns };