import store from '@/state/store.js';
let $ = require('jquery');
export default {
  name: 'create-delete-contact',
  data() {
    return {
      errors: '',
      success: '',
      loading: false
    };
  },
  methods: {
    hideModal() {
      $('#deletecontact').modal('hide');
      this.success = false;
    },
    confirmDisable() {
      this.$emit('confirmDisable');
      this.hideModal();
    }
    // deleteContact() {
    //     this.errors = '';
    //     this.loading = true
    //     let formData = this.contactForm.formData
    //     let payload = {
    //         contact_id: formData.contact.id,
    //         key: formData.key,
    //         group_id: formData.contact.group_id
    //     }
    //     store.dispatch('contacts/deleteContact', payload)
    //         .then(response => {
    //             this.success = response.data.message
    //         })
    //         .catch(err => {
    //             this.loading = false
    //             if (err.response.data == undefined) {
    //                 this.errors = 'Network error try again'
    //             }
    //             if (err.response && err.response.status === 404) {
    //                 this.loading = false
    //                 this.errors = err.response.data.data.error;
    //             }
    //         })
    // },
  }
};