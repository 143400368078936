<template>
  <div class="scrollable">
    <div
      class="row justify-content-between page_medium_top_margin"
      id="__birthday__app">
      <confirmDisableBirthdayVue
        ref="confirmDisableModal"
        @confirmDisable="toggleStatus" />
      <div class="col-md-12" v-if="pageLoading">
        <div class="__loading_state">
          <div class="" style="margin-top: 10em" v-loading="pageLoading"></div>
        </div>
      </div>
      <div class="col-md-12" v-else>
        <div class="row">
          <div class="col-md-12">
            <div class="__reporting_nav__container __birthday_contacts">
              <button
                class="__reporting__nav"
                v-if="userEnableProfile"
                @click="
                  $router.push('/birthday-settings/contacts').catch((err) => {
                    if (err.name !== 'NavigationDuplicated') throw err;
                  })
                ">
                {{ $t("dashboard.birthday_app.birthday_contacts") }}
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-7 col-xl-8 _card_top_wrapper">
            <div
              id="__message_voice_setttings"
              class="mnotify-card"
              style="padding: 0px">
              <div class="mnotify-card-header pl-3">
                <h3 class="mnotify-card-header-text py-0 my-0">
                  {{ $t("dashboard.birthday_app.birthday_sub_text") }}
                </h3>
              </div>
              <div class="mnotify-card-body">
                <div class="__birday_app__initial__state">
                  <div class="__enable_sections py-4 d-flex align-items-center">
                    <div class="enable__inner pl-3 d-flex">
                      <el-button
                        id="_enable_btn"
                        class="notranslate"
                        @click="enableBtn"
                        type="primary"
                        v-if="!enableSettings">
                        {{ $t("dashboard.birthday_app.enable") }}
                      </el-button>
                      <el-button
                        id="_disable_btn"
                        class="notranslate"
                        :loading="enableLoading"
                        @click="openConfirmDisableModal"
                        type="primary"
                        style="width: auto"
                        v-if="enableSettings">
                        {{ $t("dashboard.birthday_app.disable") }}
                      </el-button>
                      <div class="__enable_info">
                        <div class="row">
                          <div
                            class="col-md-12 col-lg-10 col-xl-7 __enable__info__text">
                            {{
                              !enableSettings
                                ? `Enabling the birthday app would send messages
                                                        to clients when it is their birthday.This would in turn affect
                                                        your cost charges.`
                                : `Disabling the birthday app would prevent
                                                        clients from receiving messages on their birthday.`
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="__birthday__app__settings" v-if="showpage">
                    <div class="col-md-12">
                      <el-form
                        style="width: 100%"
                        :model="formData"
                        :rules="rules"
                        ref="ruleForm"
                        @submit.prevent.native="checkEnter">
                        <div class="row pt-3">
                          <div class="col-md-12 choose__default">
                            {{ $t("dashboard.birthday_app.sub_text") }}
                          </div>
                          <div class="col-md-12">
                            <div
                              class="__select__and_create_sender_id"
                              id="sender__id_medium">
                              <div
                                class="form_input_container sender_id_section">
                                <el-form-item
                                  :label="$t('dashboard.overview.sender_id')"
                                  prop="sender_id">
                                  <el-select
                                    style="width: 100%"
                                    :popper-append-to-body="false"
                                    v-model="formData.sender_id"
                                    filterable
                                    :placeholder="
                                      $t(
                                        'dashboard.send_message.select_sender_id'
                                      )
                                    ">
                                    <el-option
                                      v-for="(senderid, key) in senderIDs"
                                      :key="key"
                                      :label="senderid.sender_id"
                                      :value="senderid.id">
                                      <div
                                        style="width: 100%"
                                        class="d-flex justify-content-between">
                                        <div>{{ senderid.sender_id }}</div>
                                        <img
                                          style="
                                            margin-left: 2em;
                                            width: 18px;
                                            height: 18px;
                                          "
                                          src="@/assets/Shield Done.svg"
                                          alt="img" />
                                      </div>
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                              <div class="createSenderID" id="create_sender">
                                <el-button
                                  @click.prevent="openSenderID"
                                  type="primary">
                                  {{ $t("dashboard.birthday_app.create_new") }}
                                </el-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row py-3">
                          <div class="col-md-12 __select_default">
                            {{ $t("dashboard.birthday_app.default_type") }}
                          </div>
                          <div class="col-md-12 d-flex __checkbock__container">
                            <el-form-item class="__input__left" id="">
                              <el-checkbox
                                @change="handleSmsChange"
                                v-model="formData.sms"
                                :label="
                                  $t('dashboard.birthday_app.sms_birthday')
                                "
                                value="sms"></el-checkbox>
                            </el-form-item>
                            <el-form-item
                              class="__input__left"
                              style="margin-left: 1em">
                              <el-checkbox
                                @change="handleVoiceChange"
                                v-model="formData.voice"
                                :label="
                                  $t('dashboard.birthday_app.voice_birthday')
                                "
                                value="voice"></el-checkbox>
                            </el-form-item>
                          </div>
                          <div
                            class="col-md-12 contains-message-template"
                            v-if="formData.sms">
                            <div class="__select__and_create_sender_id">
                              <div
                                class="form_input_container sender_id_section select-template-section">
                                <el-form-item
                                  :label="$t('dashboard.birthday_app.template')"
                                  prop="template_id">
                                  <el-select
                                    id="_select_sender_id"
                                    style="width: 100%"
                                    v-model="formData.template_id"
                                    :popper-append-to-body="false"
                                    filterable
                                    :placeholder="
                                      $t(
                                        'dashboard.birthday_app.select_template'
                                      )
                                    ">
                                    <el-option
                                      v-for="(temp, key) in templates"
                                      :key="key"
                                      :label="temp.title"
                                      :value="temp.id">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                            </div>
                            <div
                              class="createSenderID create-template-container">
                              <el-button
                                @click.prevent="createMessageTemplate"
                                type="primary">
                                {{
                                  $t("dashboard.birthday_app.create_template")
                                }}
                              </el-button>
                            </div>
                          </div>
                          <div class="col-md-12" v-if="formData.voice">
                            <div class="__select__and_create_sender_id">
                              <div
                                class="form_input_container sender_id_section">
                                <el-form-item
                                  :label="
                                    $t('dashboard.birthday_app.voice_note')
                                  "
                                  prop="voice_note_id">
                                  <el-select
                                    id="_select_sender_id"
                                    v-model="formData.voice_note_id"
                                    filterable
                                    :popper-append-to-body="false"
                                    style="width: 100%"
                                    :placeholder="
                                      $t(
                                        'dashboard.birthday_app.voice_placeholder'
                                      )
                                    ">
                                    <el-option
                                      v-for="(temp, key) in voiceTemplates"
                                      :key="key"
                                      :label="temp.title"
                                      :value="temp.id">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                            </div>
                            <div class="createSenderID">
                              <el-button
                                @click.prevent="createVoiceTemplate"
                                type="primary">
                                {{
                                  $t(
                                    "dashboard.birthday_app.create_voice_template"
                                  )
                                }}
                              </el-button>
                            </div>
                          </div>
                          <div class="col-md-11 save__settings mt-3 pb-5">
                            <Alert
                              :message="enableMessage"
                              v-if="enableMessage"
                              :type="type" />
                            <el-button
                              :loading="loading"
                              @click="saveSettings('ruleForm')"
                              type="primary"
                              id="save-settings"
                              style="
                                height: 43px;
                                font-size: 16px;
                                font-weight: 500;
                              ">
                              {{ $t("dashboard.birthday_app.save") }}
                            </el-button>
                          </div>
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-sm-12 col-md-12 col-lg-5 col-xl-4 right-column-section"
            v-if="userEnableProfile">
            <div class="row">
              <div class="col-md-12">
                <SmsMessages @refreshTemplates="refetchTemplates" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <VoiceMessages @refreshTemplates="refetchVoiceTemplates" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SenderIDModal ref="senderID" @hideModal="hideModal" />
      <MessageModal
        @saveTemplate="refetchVoiceTemplates"
        @saveVoiceTemplate="refetchVoiceTemplates"
        :from="from"
        :payload="dataPayload"
        ref="messageModal"
        @closeBirthday="closeCreateTemplateBirthday"
        @deleteSuccess="refetchVoiceTemplates" />
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import MessageModal from "../messages/messageModal.vue";
  import store from "@/state/store.js";
  import SenderIDModal from "../../dashboard/main/senderIDModal.vue";
  import SmsMessages from "./smsmessages.vue";
  import VoiceMessages from "./voicesms.vue";
  import Storage from "@/services/storage.js";
  import confirmDisableBirthdayVue from "./confirmDisableBirthday.vue";
  export default {
    name: "birthday-settings",
    components: {
      SenderIDModal,
      SmsMessages,
      VoiceMessages,
      MessageModal,
      confirmDisableBirthdayVue,
    },
    data() {
      return {
        value: "",
        enableSettings: false,
        connection: null,
        templates: [],
        voiceTemplates: [],
        fileUploadSuccess: false,
        pageLoading: false,
        showpage: false,
        error: "",
        fileError: "",
        from: "",
        formData: {
          sender_id: "",
          template_id: "",
          status: 1,
          voice: false,
          sms: true,
          voice_note_id: "",
          smsForMissedCalls: false,
          smsformissedcalls: "",
          voice_note: [],
        },
        dataPayload: {},
        rules: {
          template_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "blur",
            },
          ],
          sender_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "blur",
            },
          ],
          voice_note_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "blur",
            },
          ],
        },
        loading: false,
        isFile: false,
        enableLoading: false,
        userEnableProfile: false,
        enableMessage: "",
        type: "alert-danger",
      };
    },
    mounted() {
      store.dispatch("senderid/getSenderIDs");
      store.dispatch("campaigns/fetchTemplate").then(({ data }) => {
        if (!data.length) return (this.templates = []);
        this.templates = data.filter((d) => d.type == 3);
      });
      store.dispatch("voice/getVoiceTemplates").then(({ data }) => {
        if (!data.length) return (this.voiceTemplates = []);
        this.voiceTemplates = data;
      });
      this.getBirthdaySettings();
      // Analytics
      this.$mixpanel.track("Birthday App Page");
      const storage = new Storage();
      if (storage.getSavedState("currentUser").country === "CI") {
        this.$mixpanelFranco.track("Birthday App Page");
      }
    },
    computed: {
      corporate() {
        const storage = new Storage();
        return storage.getSavedState("isCorporate");
      },
      senderIDs() {
        return store.getters["senderid/senderIDs"];
      },
      voiceNotes() {
        return store.getters["voice/voiceNotes"];
      },
    },
    methods: {
      saveVoiceTemplate() {
        // setTimeout(() => { location.reload() }, 200)
        store.dispatch("campaigns/fetchTemplate").then(({ data }) => {
          if (!data.length) return (this.templates = []);
          this.templates = data.filter((d) => d.type == 3);
        });
      },
      showModal() {
        let element = this.$refs.messageModal.$el;
        $(element).modal("show");
      },
      createVoiceTemplate() {
        store.dispatch("uistate/addContactFormData", {
          title: this.$t("dashboard.birthday_app.create_message"),
          type: "voiceTemplate",
        });
        this.showModal();
      },
      createMessageTemplate() {
        store.dispatch("uistate/addContactFormData", {
          title: this.$t("dashboard.birthday_app.create_a_template"),
          type: "template",
          fromTemplate: true,
        });
        this.showModal();
      },
      hideModal() {
        let element = this.$refs.senderID.$el;
        $(element).modal("hide");
      },
      closeCreateTemplateBirthday() {
        let element = this.$refs.messageModal.$el;
        $(element).modal("hide");
      },
      enableBtn() {
        this.enableLoading = true;
        this.enableSettings = true;
        this.showpage = true;
      },
      async toggleStatus() {
        if (!this.userEnableProfile) {
          this.enableSettings = false;
          this.showpage = false;
          return;
        }
        this.enableLoading = true;
        try {
          const { data } = await store.dispatch("birthday/enableBirthday", {
            status: 0,
          });
          window.location.reload();
        } catch (error) {
          this.enableLoading = false;
        }
      },
      async getBirthdaySettings() {
        this.pageLoading = true;
        try {
          const { data } = await store.dispatch("birthday/getBirthdaySettings");
          this.pageLoading = false;
          if (data.status == 1) {
            this.enableSettings = true;
            this.showpage = true;
            this.formData.sender_id = data.sender.id;
            this.userEnableProfile = true;
            if (data.template.type === 3) {
              this.formData.voice = false;
              this.formData.sms = true;
              this.formData.template_id = data.template.id;
            } else {
              this.formData.voice = true;
              this.formData.sms = false;
              this.formData.voice_note_id = data.template.id;
            }
          }
        } catch (error) {
          this.pageLoading = false;
        }
      },
      handleFileExceed() {
        this.$message.error(
          this.$t("dashboard.send_message.sms.excel_sms.note.min_file")
        );
      },
      handleChange(file, fileList) {
        const isLt5M = file.size / 1024 / 1024 > 5;
        this.fileName = file.name;
        if (isLt5M) {
          return (this.errors = this.$t("dashboard.birthday_app.audio_max"));
        }
        this.formData.voice_note = file.raw;
        this.fileUploadSuccess = true;
        this.isFile = true;
        this.fileError = null;
      },
      saveData() {
        // voice note voice_note
        const formData = new FormData();
        formData.append("sender_id", this.formData.sender_id);
        formData.append("status", 1);
        if (this.formData.sms) {
          formData.append("template_id", this.formData.template_id);
        }
        if (this.formData.voice) {
          formData.append("template_id", this.formData.voice_note_id);
        }
        store
          .dispatch("birthday/birthDaySettings", formData)
          .then((res) => {
            if (res) {
              this.loading = false;
              this.$router.push("/birthday-settings/contacts").catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.enableMessage = this.$t("misc.network_error");
            }
          });
      },
      saveSettings(formName) {
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveData();
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      openSenderID(e) {
        store.dispatch("senderid/setSenderModalState", {
          title: this.$t(
            "dashboard.send_message.message_templates.form.message.create_sender_id"
          ),
          type: "newSenderID",
          btnTitle: this.$t(
            "dashboard.send_message.message_templates.form.message.create_sender_id"
          ),
        });
        let element = this.$refs.senderID.$el;
        $(element).modal("show");
      },
      handleSmsChange() {
        if (this.formData.sms) {
          this.formData.voice = false;
        }
      },
      handleVoiceChange() {
        if (this.formData.voice) {
          this.formData.sms = false;
        }
      },
      openSenderIDModal() {
        store.dispatch("senderid/setSenderModalState", {
          title: this.$t(
            "dashboard.send_message.message_templates.form.message.create_sender_id"
          ),
          type: "newSenderID",
          btnTitle: this.$t(
            "dashboard.send_message.message_templates.form.message.create_sender_id"
          ),
        });
        let element = this.$refs.senderID.$el;
        $(element).modal("show");
      },
      openConfirmDisableModal() {
        let element = this.$refs.confirmDisableModal.$el;
        $(element).modal("show");
      },
      refetchTemplates() {
        store.dispatch("campaigns/fetchTemplate").then(({ data }) => {
          if (!data.length) return (this.templates = []);
          this.templates = data.filter((d) => d.type == 3);
        });
      },
      refetchVoiceTemplates() {
        store.dispatch("voice/getVoiceTemplates").then(({ data }) => {
          if (!data.length) return (this.voiceTemplates = []);
          this.voiceTemplates = data;
        });
      },
    },
  };
</script>

<style lang="scss">
  .__select__and_create_sender_id {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 500px;
    max-width: 100%;
    justify-content: space-between;

    .sender_id_section {
      width: 60%;
    }

    .createSenderID {
      width: 30%;
      margin-top: 2.3em;
    }
  }

  .__birthday_contacts {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  #__birthday__app {
    #__message_voice_setttings {
      min-height: 785px;
    }

    .__enable_sections {
      width: 100%;
      border-bottom: 2px solid #f3f3f4;
    }

    .__enable_info {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #484a4f;
    }

    button {
      outline: none;
      border: none;
    }

    #_enable_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 43px;
      width: 280px;
      background: #f7921c;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 159.3%;
      text-align: center;
      color: #ffffff;
      margin-right: 1em;
    }

    #_disable_btn {
      margin-right: 1em;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 43px;
      width: fit-content;
      padding-inline: 10px;
      background: hsl(0, 97%, 59%);
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 159.3%;
      text-align: center;
      color: #ffffff;
    }

    .choose__default {
      width: 90%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #484a4f;
    }

    .my_form_input_container {
      width: 375px;
      margin-right: 1em;
    }

    .__create__new__sender_id {
      height: 43px;
      margin-top: 1em;
      margin-bottom: 1em;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 159.3%;
      text-align: center;
      color: #f7921c;
      width: 160px;
      background: #f5f5f5;
      border-radius: 5px;
    }

    .__select_default {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #484a4f;
    }

    em {
      color: #f7921c;
    }

    p {
      margin-bottom: 0px;
    }

    .__upload__audio__file {
      font-style: normal;
      font-weight: 500;
      font-size: 16.3721px;
      line-height: 20px;
      text-align: center;
      color: #575761;
    }

    .file_type {
      font-style: normal;
      font-weight: normal;
      font-size: 12.7338px;
      line-height: 20px;
      text-align: center;
      color: #575761;
    }

    .drag_file {
      font-style: normal;
      font-weight: normal;
      font-size: 12.7338px;
      line-height: 20px;
      color: #575761;
    }

    .el-upload__text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .el-upload__text img {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9 !important;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 100% !important;
      height: 305.8px !important;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #f7921c;
    }

    .save__settings {
      display: flex;
      justify-content: flex-end;
    }
  }

  /*  sms, voice messages */
  #sms_voice__card {
    height: 376px;
  }

  .__sms_voice__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #484a4f;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  ._sms__voice__inner {
    width: 100%;
    display: flex;
    height: calc(376px - 50px);
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
  }

  .btn__ {
    font-weight: 500;
    font-size: 14px;
    /* font-size: 14px */
    line-height: 159.3%;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._sms_voice_message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    background: rgba(212, 216, 226, 0.2);
    border-radius: 31px;
  }

  .__select__message__template {
    max-width: 544px;
  }

  /*
    ._enable_btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 199px;
        height: 47.9px;
        background: #F5F5F5;
        border-radius: 5px;
    }
    /* @import url("../../../styles/birthday/index.scss"); */
  @media screen and (max-width: 1200px) {
    #_enable_btn,
    #_disable_btn {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      width: auto;
    }

    #__birthday__app {
      height: 70vh;
      overflow: scroll;
      overflow-x: hidden;
    }
  }

  @media screen and (max-width: 580px) {
    .__select__and_create_sender_id {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .sender_id_section {
        width: 100%;
      }

      .createSenderID {
        width: 30%;
        margin-top: 1em;
        float: right !important;
        margin-bottom: 1em;
      }
    }

    #__birthday__app {
      height: 70vh;
      overflow: scroll;
      overflow-x: hidden;
    }
  }

  @media screen and (max-width: 768px) {
    .enable__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 1em;
    }

    #_enable_btn,
    #_disable_btn {
      margin-bottom: 1em;
    }

    #__birthday__app {
      ._card_top_wrapper {
        margin-bottom: 2em;
      }

      #__message_voice_setttings {
        min-height: 100%;
        padding-bottom: 3em !important;
      }

      .btn__ {
        width: 220px !important;
      }
    }

    #save-settings {
      margin-top: 2em;
      margin-bottom: 2em;
    }

    #__birthday__app {
      height: 70vh;
      overflow: scroll;
      overflow-x: hidden;
    }
  }

  @media screen and (max-width: 500px) {
    .my_form_input_container {
      width: 100% !important;
      margin: 0px;
    }

    .__create__new__sender_id {
      width: 100%;
      margin-top: 0.5em;
      margin-bottom: 1em;
    }

    .__select__and_create_sender_id {
      flex-direction: column;
    }

    #__birthday__app {
      .__enable__info__text {
        text-align: center;
      }

      #_enable_btn,
      #_disable_btn {
        width: fit-content !important;
        margin-bottom: 1em;
        font-style: normal;
        font-weight: 500;
        height: 40px;
        font-size: 13px;
      }

      .btn__ {
        width: 200px !important;
        height: 40px;
        font-style: normal;
        font-weight: 500;
        height: 40px;
        font-size: 13px;
      }

      .__checkbock__container {
        display: flex;
        flex-direction: column;
      }

      .__checkbock__container .__input__left {
        margin: 0px !important;
        padding: 0px !important;
      }

      .__create__new__sender_id {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }

    #__birthday__app {
      height: 70vh;
      overflow: scroll;
      overflow-x: hidden;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1285px) {
    .enable__inner {
      flex-direction: column;
    }

    #_enable_btn,
    #_disable_btn {
      margin-bottom: 1em;
    }

    #__birthday__app {
      height: 70vh;
      overflow: scroll;
      overflow-x: hidden;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1224px) {
    #sender__id_medium {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      #create_sender {
        margin-top: 0.5em;
      }
    }

    #__birthday__app {
      height: 70vh;
      overflow: scroll;
      overflow-x: hidden;
    }
  }

  .__birday_app__initial__state {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .right-column-section {
    height: 70vh;
    overflow: auto;
  }

  .contains-message-template {
    display: flex;
    max-height: 80%;
    // background-color: red;
    max-width: 70%;
    align-items: flex-end;
  }

  .create-template-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
  }

  .select-template-section {
    min-width: 90%;
  }

  .scrollable {
    overflow-y: hidden;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
  }
</style>
